import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  apiGetSauda,
  apiGetSaudaList,
  apiUpdateSauda,
} from "../../../services/SaudaService";
import {
  apigetBuyerOptions,
  apiGetAllBuyer,
} from "../../../services/BuyerService";

export const getSelectedSauda = createAsyncThunk(
  "sauda/:date",
  async (data) => {
    try {
      const response = await apiGetSauda(data);
      return response;
    } catch (error) {
      return error?.response || error.toString();
    }
  }
);

export const getAllSaudaLists = createAsyncThunk(
  "sauda/data/get",
  async (data) => {
    try {
      const response = await apiGetSaudaList(data);
      return response;
    } catch (error) {
      return error?.response || error.toString();
    }
  }
);

export const getBuyerOptions = createAsyncThunk(
  "farmer/data/option",
  async (data) => {
    try {
      const response = await apigetBuyerOptions(data);
      return response;
    } catch (error) {
      return error?.response || error.toString();
    }
  }
);

export const updateSauda = createAsyncThunk(
  "sauda/data/update",
  async (data) => {
    try {
      const response = await apiUpdateSauda(data);
      return response;
    } catch (error) {
      return error?.response || error.toString();
    }
  }
);

export const getAllBuyer = createAsyncThunk("buyer/data/get", async (data) => {
  try {
    const response = await apiGetAllBuyer(data);
    return response;
  } catch (error) {
    return error?.response || error.toString();
  }
});

export const initialTableData = {
  total: 0,
  pageIndex: 1,
  pageSize: 10,
  query: "",
  sort: {
    order: "",
    field: "",
  },
};

const dataSlice = createSlice({
  name: "saudalists/data",
  initialState: {
    loading: false,
    farmers: [],
    tableData: initialTableData,
  },
  reducers: {
    setOrderList: (state, action) => {
      state.farmers = action.payload;
    },
    setTableData: (state, action) => {
      state.tableData = action.payload;
    },

    setSaudaDataDelete: (state, action) => {
      const indexToDelete = action.payload;
      state.sauda.splice(indexToDelete, 1);
    },

    setSaudaData: (state, action) => {
      // state.saudaData = action.payload;
      state.sauda = [...state.sauda, action.payload];

      // console.log(action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSelectedSauda.fulfilled, (state, action) => {
        state.saudaData = action.payload?.data?.data || [];
        state.tableData.total = action.payload?.data?.total || 0;
        state.loading = false;
      })
      .addCase(getSelectedSauda.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSelectedSauda.rejected, (state) => {
        state.loading = false;
        state.saudaData = [];
        state.tableData.total = 0;
      })

      .addCase(getAllBuyer.fulfilled, (state, action) => {
        state.sauda = action.payload?.data?.data || [];
        state.tableData.total = action.payload?.data?.total || 0;
        state.loading = false;
      })
      .addCase(getAllBuyer.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllBuyer.rejected, (state) => {
        state.loading = false;
        state.sauda = [];
        state.tableData.total = 0;
      })
      .addCase(updateSauda.fulfilled, (state) => {});

    // .addCase(postFarmer.fulfilled, (state) => {})
    // .addCase(deleteFarmer.fulfilled, (state) => {})
    // .addCase(putFarmer.fulfilled, (state) => {});
  },
});

export const { setOrderList, setTableData, setSaudaDataDelete, setSaudaData } =
  dataSlice.actions;

export default dataSlice.reducer;
