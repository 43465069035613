import React from "react";
import { Flex, Menu } from "antd";
import { FaRegCreditCard } from "react-icons/fa";
import { GiFarmer } from "react-icons/gi";
import turmeric from "./turmeric.png";
import { FaPowerOff } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
const Sidebar = () => {
  const navigate = useNavigate();

  return (
    <>
      <Flex align="center" justify="center">
        <div className="logo">
          <img alt="lg-img" src={turmeric} width={100} />
        </div>
      </Flex>
      <Menu
        mode="inline"
        className="menu-bar"
        defaultSelectedKeys={"/farmers"}
        onClick={(items) => {
          navigate(items.key);
        }}
        items={[
          {
            key: "/farmer/management",
            icon: <GiFarmer />,
            label: "Farmer Management",
            children: [
              {
                key: "/farmers",
                label: "Farmers",
              },
              {
                key: "/mateiral",
                label: "Material",
              },
            ],
          },
          {
            key: "/buyer/management",
            icon: <FaRegCreditCard />,
            label: "Buyer Management",
            children: [
              {
                key: "/buyers",
                label: "Buyers",
              },
              {
                key: "/buyer/orders",
                label: "Buyer Orders",
              },
            ],
          },
          {
            key: "/sauda/list",
            icon: <GiFarmer />,
            label: "Sauda",
            children: [
              {
                key: "/sauda/allsauda",
                label: " All Sauda List",
              },
              {
                key: "/sauda/add",
                label: "Create Sauda",
              },
            ],
          },
          {
            key: "/Logout",
            icon: <FaPowerOff />,
            label: "Logout",
          },
        ]}
      />
    </>
  );
};

export default Sidebar;
