import React from "react";
import { injectReducer } from "../../store/index.js";
import materialReducer from "./store";
import MaterialTable from "./components/materialTable.js";
import Card from "antd/es/card/Card.js";
import MaterialTableTools from "./components/materialTableTools.js";

injectReducer("material", materialReducer);

const Material = () => {
  return (
    <Card className="page-content">
      <div className="lg:flex items-center justify-between mb-4">
        <h3 className="mb-4 lg:mb-0 text-3xl  ">Materials</h3>
        <MaterialTableTools />
      </div>
      <MaterialTable />
    </Card>
  );
};
export default Material;
