import React, { useState, useEffect, useCallback } from "react";
import { Table, Popconfirm, notification } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { HiOutlinePencil, HiOutlineTrash } from "react-icons/hi";
import {
  getAllMaterial,
  setTableData,
  deleteMaterial,
} from "../store/dataSlice.js";
import { setSelectedMaterial, toggleNewDialog } from "../store/stateSlice.js";
import MaterialForm from "./materialForm.js";

const ActionColumn = ({ record }) => {
  const dispatch = useDispatch();
  const [api, contextHolder] = notification.useNotification();
  const confirm = async (e) => {
    try {
      const action = await dispatch(
        deleteMaterial({ material_id: record.material_id })
      );
      if (action.payload.status < 300) {
        api["success"]({
          message: "Success",
          description: "Material Deleted Successfully",
        });

        dispatch(getAllMaterial());
      } else {
        api["error"]({
          message: "Error",
          description: action?.payload?.data?.message,
        });
      }
    } catch {}
  };
  const cancel = (e) => {};

  const onEdit = () => {
    dispatch(setSelectedMaterial(record));
    dispatch(toggleNewDialog(true));
  };

  return (
    <div className="flex text-lg">
      {contextHolder}
      <span
        className={`cursor-pointer p-2 hover:text-green-500`}
        onClick={onEdit}>
        <HiOutlinePencil />
      </span>
      <Popconfirm
        title="Delete Material"
        placement="topLeft"
        description="Are you sure to delete this Material"
        onConfirm={confirm}
        onCancel={cancel}
        okText="Yes"
        okButtonProps={{
          style: { background: "blue", color: "white" },
        }}
        cancelText="No">
        <span className="cursor-pointer p-2 hover:text-red-500">
          <HiOutlineTrash />
        </span>
      </Popconfirm>
    </div>
  );
};

const columns = [
  {
    title: "AWK No.",
    dataIndex: "material_id",

    render: (text) => <a>{text}</a>,
  },
  {
    title: "Farmer",
    dataIndex: "Farmer",
    render: (record) => <div>{record?.farmer_name}</div>,
  },
  {
    title: "Material Type",
    dataIndex: "material_type",

    render: (text) => <a>{text}</a>,
  },
  {
    title: "Quantity",
    dataIndex: "material_quantity",
  },

  {
    title: "Action",
    dataIndex: "",
    render: (text, record) => <ActionColumn record={record} />,
  },
];

const MaterialTable = () => {
  const dispatch = useDispatch();

  const data = useSelector((state) => state.material.data.materials);
  console.log(data);
  const loading = useSelector((state) => state.material.data.loading);

  const { pageIndex, pageSize, query, total, sort } = useSelector(
    (state) => state.material.data.tableData
  );

  const fetchData = useCallback(() => {
    dispatch(getAllMaterial({ pageIndex, pageSize, query, sort }));
  }, [pageIndex, pageSize, query, sort]);

  useEffect(() => {
    fetchData();
  }, [pageIndex, pageSize, query, sort]);

  const handleTableChange = (pagination, filters, sorter, extra) => {
    const { order, field } = sorter;
    console.log(extra);
    const { current, pageSize } = pagination;
    dispatch(
      setTableData({
        pageIndex: current,
        pageSize,
        sort: {
          order,
          field,
        },
      })
    );
  };
  return (
    <>
      <Table
        columns={columns}
        dataSource={data}
        scroll={{
          x: 1000,
        }}
        bordered
        pagination={{ current: pageIndex, pageSize: pageSize }}
        loading={loading}
        sorter={sort}
        onChange={handleTableChange}
      />
      <MaterialForm />
    </>
  );
};

export default MaterialTable;
