import React from "react";
import { injectReducer } from "../../store/index.js";
import buyerReducer from "./store";
import BuyerTable from "./components/buyerTable.js";
import Card from "antd/es/card/Card.js";
import BuyerTableTools from "./components/buyerTableTools.js";

injectReducer("buyer", buyerReducer);

const Buyer = () => {
  return (
    <Card className="page-content">
      <div className="lg:flex items-center justify-between mb-4">
        <h3 className="mb-4 lg:mb-0 text-3xl  ">Buyer</h3>
        <BuyerTableTools />
      </div>
      <BuyerTable />
    </Card>
  );
};
export default Buyer;
