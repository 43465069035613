import ApiService from "./ApiService";

export async function apiPostSaudaList(data) {
  return ApiService.fetchData({
    url: "/sauda/register",
    method: "post",
    data,
  });
}

export async function apiGetSaudaList() {
  const url = `/sauda/lists`;
  return ApiService.fetchData({
    url,
    method: "get",
  });
}

export async function apiUpdateSauda(data) {
  return ApiService.fetchData({
    url: `/sauda/update/id`,
    method: "post",
    data,
  });
}
export async function apiPostSauda(data) {
  return ApiService.fetchData({
    url: "/sauda/register",
    method: "post",
    data,
  });
}

export async function apiGetSauda(data) {
  // const url = `https://auction.5techg.com/api/v1/web/sauda?Date=2024-03-03`;
  const url = `/sauda?Date=${data.Date}`;
  return ApiService.fetchData({
    url,
    method: "get",
  });
}
