import React, { useEffect, useMemo, useState } from "react";
import {
  Modal,
  Form,
  Input,
  Button,
  Col,
  Row,
  Typography,
  notification,
  Select,
  Flex,
  DatePicker,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  getFarmerOption,
  getBuyerOptions,
  setSaudaData,
} from "../store/dataSlice";

import { toggleSetDate } from "../store/stateSlice";
import { number } from "yup";

const { Option } = Select;

// const style = {
//   padding: "0px 10px",
// };

const SaudaForm = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const [form] = Form.useForm();

  const [farmer_id, setFarmer_id] = useState("");
  const [material_id, setMaterial_id] = useState("");
  const [{ type, quantity }, setSelectedMD] = useState({
    type: "",
    quantity: "",
  });

  const farmers = useSelector((state) => state.farmer.data.farmers);

  const materials = useSelector((state) => state.material.data.materials);
  console.log(materials);

  const saudaData = useSelector((state) => state.createSauda.data.sauda);

  const SaudaListDate = useSelector((state) => state.createSauda.state.date);

  useEffect(() => {
    if (material_id) {
      const mat = materials.filter(
        (order) => order.material_id === material_id
      );
      // console.log(mat[0]);

      setSelectedMD({
        type: mat[0].material_type,
        quantity: mat[0].material_quantity,
      });
      form.setFieldsValue({
        material_type: type,
        material_quantity: quantity,
      });
    }
  }, [material_id, type, quantity]);

  const filteredMaterialOptions = materials.filter(
    (order) => order.farmer_id === farmer_id
  );

  const materialOptions = farmer_id
    ? filteredMaterialOptions.map((order) => ({
        label: order.material_id,
        value: order.material_id,
      }))
    : [];

  // console.log(saudaData);
  const farmerOptions = farmers
    ? farmers.map((farmer) => ({
        label: farmer.farmer_name,
        value: farmer.farmer_id,
      }))
    : [];

  useEffect(() => {
    const fetchFarmer = async () => {
      setLoading(true);

      try {
        await dispatch(getFarmerOption());
      } catch (error) {
        console.error("Error fetching farmers:", error);
      } finally {
        setLoading(false);
      }
    };

    // const fetchBuyer = async () => {
    //   setLoading(true);

    //   try {
    //     await dispatch(getBuyerOptions());
    //   } catch (error) {
    //     console.error("Error fetching buyers:", error);
    //   } finally {
    //     setLoading(false);
    //   }
    // };
    fetchFarmer();
    // fetchBuyer();
  }, []);

  const onFinish = async (values) => {
    console.log(saudaData);
    try {
      if (!Array.isArray(saudaData)) {
        throw new Error("Invalid sauda data");
      }
      const existingSauda = saudaData.find(
        (sauda) => sauda.awk_number === values.awk_number
      );

      if (existingSauda) {
        api.error({
          message: "Error",
          description: "This data already exists.",
        });
      } else {
        dispatch(setSaudaData(values));
        form.resetFields();
        api.success({
          message: "Success",
          description: "Sauda Added.",
        });
      }
    } catch (err) {
      console.error("Error submitting form:", err);
      api.error({
        message: "Error",
        description: "Failed to submit the form.",
      });
    } finally {
      setLoading(false);
    }

    //   // Dispatch the action to store form data in Redux store
    //   dispatch(setSaudaData(values));
    //   // make api call to save data

    //   // Reset form fields if needed
    //   form.resetFields();

    //   // Show success notification or perform any other actions
    //   api.success({
    //     message: "Success",
    //     description: "Form submitted successfully.",
    //   });
    // } catch (err) {
    //   console.error("Error submitting form:", err);
    //   api.error({
    //     message: "Error",
    //     description: "Failed to submit the form.",
    //   });
    // } finally {
    //   setLoading(false);
    // }
  };

  const SearchInput = (props) => {
    const [data, setData] = useState([...props.options]);
    const handleSearch = (newValue) => {
      console.log(newValue);
      const filteredOptions = props.options.filter((f) => {
        if (typeof f.label === "string") {
          return f.label.toLowerCase().includes(newValue.toLowerCase());
        } else {
          return String(f.label).includes(String(newValue));
        }
      });
      setData(filteredOptions);
    };

    return (
      <Select
        showSearch
        name={props.name}
        value={props.value ? data.find((f) => f.value === props.value) : ""}
        placeholder={props.placeholder}
        style={props.style}
        defaultActiveFirstOption={false}
        suffixIcon={null}
        filterOption={false}
        onSearch={handleSearch}
        onChange={props.onChange}
        notFoundContent={null}
        options={data}
        size="large"
      />
    );
  };

  const dateOnChange = (date, dateString) => {
    console.log(date, dateString);
    dispatch(toggleSetDate(dateString));
  };

  return (
    <div className="mb-4">
      {contextHolder}

      <Flex justify="flex-end">
        <DatePicker
          size="large"
          required
          onChange={dateOnChange}
          placeholder={SaudaListDate}
        />
      </Flex>

      <Form
        form={form}
        initialValues={{
          farmer_id: "",
          buyer_id: "",
          awk_number: "",

          material_price: "",

          remember: false,
        }}
        onFinish={onFinish}
        autoComplete="off">
        <Flex align="center" gap={10} justify="start" wrap="wrap">
          <div></div>
          <div>
            <Typography>Farmer</Typography>
            <Form.Item
              hasFeedback
              name="farmer_id"
              rules={[
                {
                  required: true,
                  message: "Required",
                },
              ]}>
              <SearchInput
                value={farmer_id}
                name="farmer_id"
                options={farmerOptions}
                onChange={(value) => {
                  setFarmer_id(value);
                  form.setFieldsValue({ farmer_id: value });
                }}
                style={{ width: "160px" }}
              />
            </Form.Item>
          </div>

          <div>
            <Typography>AWK No.</Typography>
            <Form.Item
              name="awk_number"
              rules={[
                {
                  required: true,
                  message: "Required",
                },
              ]}
              hasFeedback>
              <SearchInput
                value={material_id}
                name="awk_number"
                options={materialOptions}
                onChange={(value) => {
                  setMaterial_id(value);
                  form.setFieldsValue({
                    awk_number: value,
                  });
                }}
                style={{ width: "130px" }}
              />
            </Form.Item>
          </div>
          <div>
            <Typography>Type</Typography>
            <Form.Item
              name="material_type"
              rules={[
                {
                  required: true,
                  message: "Required",
                },
              ]}
              hasFeedback>
              <Input value={type} size="large" />
            </Form.Item>
          </div>
          <div>
            <Typography>Quantity</Typography>
            <Form.Item
              name="material_quantity"
              rules={[
                {
                  required: true,
                  message: "Required",
                },
              ]}
              hasFeedback>
              <Input value={quantity} size="large" />
            </Form.Item>
          </div>
          {/* <div>
            <Typography>Price</Typography>
            <Form.Item
              name="material_price"
              rules={[
                {
                  required: true,
                  message: "Required",
                },
              ]}
              hasFeedback>
              <Input
                size="large"
                placeholder="Enter your Price"
                type="number"
              />
            </Form.Item>
          </div> */}
          {/* <div>
            <Typography>Buyer</Typography>
            <Form.Item
              name="buyer_id"
              rules={[
                {
                  required: true,
                  message: "Required",
                },
              ]}
              hasFeedback>
              <SearchInput
                value={buyer_id}
                name="buyer_id"
                placeholder="Select Buyer"
                options={buyerOptions}
                onChange={(value) => {
                  setBuyer_id(value);
                  form.setFieldsValue({ buyer_id: value });
                }}
                style={{ width: "160px" }}
              />
            </Form.Item>
          </div> */}
          <div>
            <Button
              key="submit"
              type="primary"
              className="btn"
              htmlType="submit">
              Submit
            </Button>
          </div>
        </Flex>
      </Form>
    </div>
  );
};
export default SaudaForm;
