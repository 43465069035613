import React, { useEffect, useState } from "react";
import "./App.css";
import { Routes, Route, Navigate } from "react-router-dom";
import Sidebar from "./components/Sidebar/Sidebar";
import CustomHeader from "./components/Header/Header";
import Login from "./view/login/Login";
import { Layout, Button } from "antd";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import AppRoutes from "./components/AppRoutes";

const { Sider, Header, Content } = Layout;

function App() {
  const [collapsed, setCollapsed] = useState(false);
  const user = localStorage.getItem("user");

  useEffect(() => {}, [user]);
  return (
    <>
      {!user ? (
        <Login />
      ) : (
        <Layout>
          <Sider
            theme="light"
            trigger={null}
            collapsible
            collapsed={collapsed}
            onCollapse={(value) => setCollapsed(value)}
            className="sider"
          >
            <Sidebar />

            <Button
              type="text"
              icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              onClick={() => setCollapsed(!collapsed)}
              className="triger-btn"
            />
          </Sider>
          <Layout>
            <Header className="header">
              <CustomHeader />
            </Header>
            <Content className="content">
              <AppRoutes />
            </Content>
          </Layout>
        </Layout>
      )}
    </>
  );
}

export default App;
