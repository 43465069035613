import React, { useEffect, useCallback, useState } from "react";
import { useParams, Navigate, useNavigate } from "react-router-dom";
import {
  Table,
  Popconfirm,
  notification,
  Button,
  Flex,
  Space,
  Select,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { HiOutlineTrash } from "react-icons/hi";
import { Card, DatePicker } from "antd";
import { getSelectedSauda } from "../store/dataSlice.js";
import BuyerPriceInput from "./buyerPriceInput.js";
import { getBuyerOptions, updateSauda } from "../store/dataSlice.js";
import {
  toggleSaudaId,
  toggleSaudaIdPrice,
  toggleSetBuyerId,
  toggleSetMaterialPrice,
} from "../store/stateSlice.js";
import BuyerNameInput from "./buyerNameInput.js";

const ActionColumn = ({ record }) => {
  const dispatch = useDispatch();
  const [api, contextHolder] = notification.useNotification();
  const BuyerId = useSelector((state) => state.sauda.state.buyer_id);
  const MaterialPrice = useSelector(
    (state) => state.sauda.state.material_price
  );
  const saudaId = useSelector((state) => state.sauda.state.sauda_id);
  const saudaIdPrice = useSelector((state) => state.sauda.state.sauda_id_price);

  //   const confirm = async (e) => {
  //     try {
  //       const action = await dispatch(
  //         deleteFarmer({ farmer_id: record.farmer_id })
  //       );
  //       if (action.payload.status < 300) {
  //         api["success"]({
  //           message: "Success",
  //           description: "Farmer Deleted Successfully",
  //         });

  //         dispatch(getAllFarmer());
  //       } else {
  //         api["error"]({
  //           message: "Error",
  //           description: action?.payload?.data?.message,
  //         });
  //       }
  //     } catch {}
  //   };
  const cancel = (e) => {};

  const handleSubmitRow = async () => {
    // console.log({
    //   sauda_id: record.sauda_id,
    //   buyer_id: BuyerId,
    //   material_price: MaterialPrice,
    // });

    if (
      saudaId === saudaIdPrice &&
      saudaId === record.sauda_id &&
      saudaIdPrice === record.sauda_id
    ) {
      try {
        const action = await dispatch(
          updateSauda({
            sauda_id: record.sauda_id,
            buyer_id: BuyerId,
            material_price: MaterialPrice,
          })
        );

        if (action.payload.status < 300) {
          api["success"]({
            message: "Success",
            description: "Details Updated Successfully",
          });
          dispatch(toggleSetBuyerId(""));
          dispatch(toggleSetMaterialPrice(""));
          dispatch(toggleSaudaId(""));
          dispatch(toggleSaudaIdPrice(""));
        } else {
          api["error"]({
            message: "Error",
            description: action?.payload?.data?.message,
          });
        }
      } catch (er) {
        api["error"]({
          message: "Failed to Submit ",
          description: er,
        });
      }
    } else {
      api["error"]({
        message: "Error",
        description: "Please Enter Buyer and Price for Submitted Row",
      });
    }
  };

  return (
    <Flex align="center" gap={15} wrap>
      {contextHolder}

      <Popconfirm
        title="ARE YOU SURE?"
        placement="topLeft"
        description="Delete this sauda from current list?"
        // onConfirm={confirm}
        onCancel={cancel}
        okText="Yes"
        okButtonProps={{
          style: { background: "blue", color: "white" },
        }}
        cancelText="No">
        <span className="cursor-pointer p-2 hover:text-red-500">
          <HiOutlineTrash size={20} />
        </span>
      </Popconfirm>

      <Button size="small" onClick={handleSubmitRow}>
        Submit
      </Button>
    </Flex>
  );
};

const SaudaTable = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [api, contextHolder] = notification.useNotification();

  const [saudaData, setSaudaData] = useState([]);
  const [loading, setLoading] = useState(false);

  // const buyers = useSelector((state) => state.buyer.data.buyers);

  // const buyerOptions = buyers
  //   ? buyers.map((buyer) => ({
  //       label: buyer.buyer_name,
  //       value: buyer.buyer_id,
  //     }))
  //   : [];

  // useEffect(() => {
  //   const fetchBuyer = async () => {
  //     setLoading(true);

  //     try {
  //       await dispatch(getBuyerOptions());
  //     } catch (error) {
  //       console.error("Error fetching buyers:", error);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  //   fetchBuyer();
  //   setLoading(false);
  // }, []);

  // const farmers = useSelector((state) => state.farmer.data.farmers);
  // const buyers = useSelector((state) => state.buyer.data.buyers);
  // const loading = useSelector((state) => state.farmer.data.loading);

  const { pageIndex, pageSize, query, total, sort } = useSelector(
    (state) => state.sauda.data.tableData
  );

  const { date } = useParams();

  const formatedDate = () => {
    // console.log("loger 72", date);

    if (typeof date === "string") {
      return date;
    }

    return date.toJSON().substring(0, 10);
  };

  const fetchData = useCallback(() => {
    setLoading(true);
    dispatch(
      getSelectedSauda({
        Date: formatedDate(date),
        pageIndex,
        pageSize,
        query,
        sort,
      })
    )
      .then((response) => {
        console.log(response.payload.data.data);
        setSaudaData(response.payload.data.data);
      })
      .catch((error) => {
        console.error(error);
        // Handle error if needed
      });
    setLoading(false);
  }, [dispatch, date, pageIndex, pageSize, query, sort]);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  //   const handleTableChange = (pagination, filters, sorter, extra) => {
  //     const { order, field } = sorter;
  //     console.log(extra);
  //     const { current, pageSize } = pagination;
  //     dispatch(
  //       setTableData({
  //         pageIndex: current,
  //         pageSize,
  //         sort: {
  //           order,
  //           field,
  //         },
  //       })
  //     );
  //   };
  const handleSelectChng = async (value, record) => {
    console.log(value, record.sauda_id);
    try {
      const action = await dispatch(
        updateSauda({
          sauda_id: record.sauda_id,
          sauda_confirmation: value,
        })
      );

      if (action.payload.status < 300) {
        api["success"]({
          message: "Success",
          description:
            value === "confirm"
              ? "Sauda Confirmed"
              : "Sauda set to Not-confirm.....",
        });
      } else {
        api["error"]({
          message: "Error",
          description: action?.payload?.data?.message,
        });
      }
    } catch (er) {
      api["error"]({
        message: "Failed to Submit ",
        description: er,
      });
    }
  };

  const renderConfirmSelect = (text, record) => {
    const defaultValue = record.sauda_confirmation
      ? record.sauda_confirmation
      : "not-confirm";

    return (
      <Select
        defaultValue={defaultValue}
        onChange={(value) => handleSelectChng(value, record)}>
        <Select.Option value="confirm">Confirm</Select.Option>
        <Select.Option value="not-confirm">Not-confirm</Select.Option>
      </Select>
    );
  };

  const columns = [
    {
      title: "Farmer",
      dataIndex: "farmer_id",
      key: "farmer_id",
    },
    {
      title: "AWK No.",
      dataIndex: "awk_number",
      key: "awk_number",
    },
    {
      title: "Type",
      className: "sauda-type",
      dataIndex: "material_type",
      key: "material_type",
    },
    {
      title: "Quantity",
      className: "sauda-quantity",
      dataIndex: "material_quantity",
      key: "material_quantity",
    },
    {
      title: "Buyer",
      dataIndex: "Buyer.buyer_name",
      key: "buyer_name",
      render: (text, record) => <BuyerNameInput row={record} />,
    },
    {
      title: "Buyer Price",
      dataIndex: "material_price",
      key: "material_price",
      render: (text, record) => <BuyerPriceInput row={record} />,
    },
    {
      title: "Confirmation",
      className: "sauda-confirmation",
      dataIndex: "sauda_confirmation",
      render: renderConfirmSelect,
    },
    {
      title: "Action",
      dataIndex: "",
      render: (text, record) => <ActionColumn record={record} />,
    },
  ];

  // console.log(saudaData);

  return (
    <Card className="page-content">
      {contextHolder}
      <div className="lg:flex items-center justify-between mb-4">
        <h3 className="mb-4 lg:mb-0 text-3xl">Sauda List Of {date}</h3>
      </div>
      <Flex
        justify="flex-end"
        style={{ marginBottom: "2rem", marginRight: "1rem" }}>
        <Button
          size="small"
          className="btn"
          type="primary"
          onClick={() => navigate(`/sauda/allsauda`)}>
          Go Back
        </Button>
      </Flex>

      <Table
        rowKey={(row) => row.sauda_id}
        columns={columns}
        dataSource={saudaData}
        scroll={{
          x: 1000,
        }}
        bordered
        loading={loading}
        sorter={sort}

        // onChange={handleTableChange}
      />
    </Card>
  );
};

export default SaudaTable;
