import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  apiAllFarmer,
  apiPostFarmer,
  apiDeleteFarmer,
  apiPutFarmer,
} from "../../../services/FarmerService";

export const getAllFarmer = createAsyncThunk(
  "farmer/data/get",
  async (data) => {
    try {
      const response = await apiAllFarmer(data);
      return response;
    } catch (error) {
      return error?.response || error.toString();
    }
  }
);

export const postFarmer = createAsyncThunk("farmer/data/post", async (data) => {
  try {
    const response = await apiPostFarmer(data);
    return response;
  } catch (error) {
    return error?.response || error.toString();
  }
});

export const putFarmer = createAsyncThunk("farmer/data/put", async (data) => {
  try {
    const response = await apiPutFarmer(data);
    return response;
  } catch (error) {
    return error?.response || error.toString();
  }
});

export const deleteFarmer = createAsyncThunk(
  "farmer/data/delete",
  async (data) => {
    try {
      const response = await apiDeleteFarmer(data);
      return response;
    } catch (error) {
      return error?.response || error.toString();
    }
  }
);

export const initialTableData = {
  total: 0,
  pageIndex: 1,
  pageSize: 10,
  query: "",
  sort: {
    order: "",
    field: "",
  },
};

const dataSlice = createSlice({
  name: "farmer/data",
  initialState: {
    loading: false,
    farmers: [],
    tableData: initialTableData,
  },
  reducers: {
    setOrderList: (state, action) => {
      state.farmers = action.payload;
    },
    setTableData: (state, action) => {
      state.tableData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllFarmer.fulfilled, (state, action) => {
        state.farmers = action.payload?.data?.data || [];
        state.tableData.total = action.payload?.data?.total || 0;
        state.loading = false;
      })
      .addCase(getAllFarmer.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllFarmer.rejected, (state) => {
        state.loading = false;
        state.farmers = [];
        state.tableData.total = 0;
      })
      .addCase(postFarmer.fulfilled, (state) => {})
      .addCase(deleteFarmer.fulfilled, (state) => {})
      .addCase(putFarmer.fulfilled, (state) => {});
  },
});

export const { setOrderList, setTableData } = dataSlice.actions;

export default dataSlice.reducer;
