import { Table, Card, Button } from "antd";
import React, { useCallback, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllSaudaLists } from "../store/dataSlice";

const AllSaudaList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [saudaData, setSaudaData] = useState([]);

  const { pageIndex, pageSize, query, total, sort } = useSelector(
    (state) => state.sauda.data.tableData
  );

  const loading = useSelector((state) => state.farmer.data.loading);

  const fetchData = useCallback(() => {
    dispatch(
      getAllSaudaLists({
        pageIndex,
        pageSize,
        query,
        sort,
      })
    )
      .then((response) => {
        // console.log(response.payload.data.data);
        setSaudaData(response.payload.data.data);
      })
      .catch((error) => {
        console.error(error);
        // Handle error if needed
      });
  }, [dispatch, pageIndex, pageSize, query, sort]);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchData]);

  const allSaudaListColumns = [
    {
      title: "Date",
      dataIndex: "sauda_date",
      key: "sauda_date",
    },
    {
      title: "Total Farmers Listed",
      dataIndex: "farmer_count",
      key: "farmer_count",
    },
    {
      title: "Total Confirmatinons",
      dataIndex: "sauda_confirmation_count",
      key: "sauda_confirmation_count",
    },
    {
      title: "Buyers",
      dataIndex: "buyer_count",
      key: "buyer_count",
    },
    {
      title: "Action",
      render: (_, record) => (
        <Button
          size="small"
          type="primary"
          className="btn"
          onClick={() => {
            navigate(`/sauda-list/${record.sauda_date}`);
          }}>
          Open Sauda
        </Button>
      ),
    },
  ];

  return (
    <Table
      rowKey={(row, index) => index + 1}
      dataSource={saudaData}
      columns={allSaudaListColumns}
      scroll={{
        x: 1000,
      }}
      bordered
      loading={loading}
      sorter={sort}
    />
  );
};

export default AllSaudaList;
