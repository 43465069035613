import { Avatar, Flex, Typography } from "antd";
import { UserOutlined } from "@ant-design/icons";

import React from "react";

const CustomHeader = () => {
  return (
    <Flex align="center" justify="space-between">
      <Typography.Title level={3} type="secondary">
        Turmeric Auction
      </Typography.Title>
      <Flex align="center" justify="end">
        <Avatar icon={<UserOutlined />} />
      </Flex>
    </Flex>
  );
};

export default CustomHeader;
