import ApiService from "./ApiService";

export async function apiAllFarmer(params) {
  return ApiService.fetchData({
    url: "/farmer",
    method: "get",
    params,
  });
}

export async function apiPostFarmer(data) {
  return ApiService.fetchData({
    url: "/farmer/register",
    method: "post",
    data,
  });
}

export async function apiDeleteFarmer(data) {
  return ApiService.fetchData({
    url: "/farmer/delete/id",
    method: "delete",
    data,
  });
}

export async function apiPutFarmer(data) {
  return ApiService.fetchData({
    url: "farmer/update/id",
    method: "put",
    data,
  });
}

export async function apigetFarmerOptions(data) {
  return ApiService.fetchData({
    url: "/farmer/option",
    method: "get",
  });
}
