import React, { useState, useEffect } from "react";
import { Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getBuyerOptions } from "../store/dataSlice";
import { toggleSetBuyerId, toggleSaudaId } from "../store/stateSlice";

const BuyerNameInput = ({ row }) => {
  const dispatch = useDispatch();

  const [buyer_id, setBuyer_id] = useState();
  const [loading, setLoading] = useState(false);

  const buyers = useSelector((state) => state.buyer.data.buyers);

  // const listData = useSelector((state) => state.sauda.data.saudaData);

  // const listDetails = listData.filter((list) => list.sauda_id === row.sauda_id);
  // console.log(listData);
  // console.log(listDetails);
  //

  const buyerOptions = buyers
    ? buyers.map((buyer) => ({
        label: buyer.buyer_name,
        value: buyer.buyer_id,
      }))
    : [];

  useEffect(() => {
    const fetchBuyer = async () => {
      setLoading(true);

      try {
        await dispatch(getBuyerOptions());
      } catch (error) {
        console.error("Error fetching buyers:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchBuyer();

    if (row.Buyer !== null) {
      setBuyer_id(row.Buyer.buyer_id);
    }
  }, []);

  const SearchInput = (props) => {
    const [data, setData] = useState([...props.options]);
    const handleSearch = (newValue) => {
      const filteredOptions = props.options.filter((f) =>
        f.label.toLowerCase().includes(newValue.toLowerCase())
      );
      setData(filteredOptions);
    };

    return (
      <Select
        showSearch
        name={props.name}
        value={props.value ? data.find((f) => f.value === props.value) : ""}
        placeholder={props.placeholder}
        style={props.style}
        defaultActiveFirstOption={false}
        suffixIcon={null}
        filterOption={false}
        onSearch={handleSearch}
        onChange={props.onChange}
        notFoundContent={null}
        options={data}
      />
    );
  };

  return (
    <SearchInput
      value={buyer_id}
      name="buyer_id"
      placeholder="Select Buyer"
      options={buyerOptions}
      onChange={(value) => {
        setBuyer_id(value);
        // row.buyer_id = value;
        dispatch(toggleSetBuyerId(value));
        dispatch(toggleSaudaId(row?.sauda_id));
      }}
      style={{ width: "160px" }}
    />
  );
};

export default BuyerNameInput;
