import { createSlice } from "@reduxjs/toolkit";

const stateSlice = createSlice({
  name: "saudalists/state",
  initialState: {
    buyer_id: "",
    material_price: "",
    sauda_id: "",
    saud_id_price: "",
  },
  reducers: {
    toggleSetBuyerId: (state, action) => {
      state.buyer_id = action.payload;
    },
    toggleSetMaterialPrice: (state, action) => {
      state.material_price = action.payload;
    },
    toggleSaudaId: (state, action) => {
      state.sauda_id = action.payload;
    },
    toggleSaudaIdPrice: (state, action) => {
      state.sauda_id_price = action.payload;
    },
  },
});

export const {
  toggleSetBuyerId,
  toggleSetMaterialPrice,
  toggleSaudaId,
  toggleSaudaIdPrice,
} = stateSlice.actions;

export default stateSlice.reducer;
