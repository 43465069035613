import React, { useState, useCallback, useEffect } from "react";
import { Card, Table, notification, Button } from "antd";
import { getBuyerOrders } from "../store/dataSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

const BuyerOrders = () => {
  const dispatch = useDispatch();
  const [api, contextHolder] = notification.useNotification();

  const [data, setData] = useState();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const fetchData = useCallback(() => {
    setLoading(true);
    dispatch(getBuyerOrders())
      .then((response) => {
        console.log(response.payload.data.data);
        setData(response.payload.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  const columns = [
    {
      title: "Buyer Name",
      dataIndex: "Buyer.buyer_name",
      key: "buyer_name",
    },
    {
      title: "Buyer Id",
      dataIndex: "Buyer.buyer_id",
      key: "buyer_id",
    },
    {
      title: "Total Orders",
      dataIndex: "orders",
      key: "orders",
    },

    {
      title: "Confirmed Orders",
      dataIndex: "confirmed_orders",
      key: "orders_confirmed",
    },
    {
      title: "Action",
      render: (_, record) => (
        <Button
          size="small"
          type="primary"
          className="btn"
          onClick={() => {
            navigate(`/buyer-list/${record["Buyer.buyer_id"]}`);
          }}>
          View
        </Button>
      ),
    },
  ];

  return (
    <Card className="page-content">
      <div className="lg:flex items-center justify-between mb-4">
        <h3 className="mb-4 lg:mb-0 text-3xl">OrdersList of Buyers</h3>
      </div>
      <Table
        dataSource={data}
        columns={columns}
        rowKey={(row) => row["Buyer.buyer_id"]}
        lo
        bordered
        loading={loading}
        scroll={{
          x: 1000,
        }}></Table>
    </Card>
  );
};

export default BuyerOrders;
