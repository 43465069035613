import ApiService from "./ApiService";

export async function apiGetAllMaterial(params) {
  return ApiService.fetchData({
    url: "/material",
    method: "get",
    params,
  });
}

export async function apiDeleteMaterial(data) {
  return ApiService.fetchData({
    url: "/material/delete/id",
    method: "delete",
    data,
  });
}

export async function apipostMaterial(data) {
  return ApiService.fetchData({
    url: "/material/register",
    method: "post",
    data,
  });
}

export async function apiputMaterial(data) {
  return ApiService.fetchData({
    url: "/material/update/id",
    method: "put",
    data,
  });
}

export async function apigetFarmerOptions(data) {
  return ApiService.fetchData({
    url: "/farmer/option",
    method: "get",
  });
}
