import React, { useEffect, useMemo, useState } from "react";
import {
  Modal,
  Form,
  Input,
  Button,
  Col,
  Row,
  Typography,
  notification,
  Select,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { toggleNewDialog, setSelectedMaterial } from "../store/stateSlice";
import {
  putMaterial,
  getAllMaterial,
  postMaterial,
  getFarmerOption,
} from "../store/dataSlice";

const { Option } = Select;

const style = {
  padding: "0px 10px",
};

const MaterialForm = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(null);
  const [api, contextHolder] = notification.useNotification();

  const materialOption = [
    { label: "Kanni", value: "kanni" },
    { label: "Gatta", value: "gatta" },
    { label: "Mari", value: "mari" },
  ];

  const [value, setValue] = useState();

  const newDialog = useSelector((state) => state.material.state.openDialog);
  const selectedMaterial = useSelector(
    (state) => state.material.state.selectedMaterial
  );

  const farmers = useSelector((state) => state.material.data.farmers);

  let farmerOptions = useMemo(() => {
    return farmers.map((f) => {
      return { label: f.label, value: f.value };
    });
  }, [farmers]);

  const onDialogClose = () => {
    dispatch(toggleNewDialog(false));
    dispatch(setSelectedMaterial({}));
    form.resetFields();
  };
  const [form] = Form.useForm();

  useEffect(() => {
    if (selectedMaterial) {
      form.resetFields();
      form.setFieldsValue({
        ...selectedMaterial,
        farmer_id: selectedMaterial?.Farmer?.farmer_id,
      });
      setValue(selectedMaterial?.Farmer?.farmer_id);
    } else {
      form.resetFields();
      form.setFieldsValue({
        farmer_id: "",
        material_type: "",
        material_quantity: "",

        remember: false,
      });
    }
  }, [selectedMaterial, form]);

  useEffect(() => {
    const fetchFarmer = () => {
      dispatch(getFarmerOption());
    };
    fetchFarmer();
  }, []);

  const onFinish = async (values) => {
    try {
      const fvalues = form.getFieldsValue();
      console.log(fvalues);
      setLoading(true);
      const action =
        Object.values(selectedMaterial).length > 0
          ? await dispatch(
              putMaterial({
                ...values,
                material_id: selectedMaterial.material_id,
              })
            )
          : await dispatch(
              postMaterial({ ...values, farmer_id: values.farmer_id })
            );
      if (action.payload.status < 300) {
        api["success"]({
          message: "Success",
          description:
            Object.values(selectedMaterial).length > 0
              ? "Material Edited Successfully"
              : "Material Added Successfully",
        });
        setLoading(false);
        dispatch(toggleNewDialog(false));
        dispatch(getAllMaterial());
        form.resetFields();
      } else {
        api["error"]({
          message: "Error",
          description: action?.payload?.data?.message,
        });
        setLoading(false);
      }
    } catch (err) {}
  };

  const SearchInput = (props) => {
    const [data, setData] = useState(farmerOptions);

    const handleSearch = (newValue) => {
      const filteredOptions = farmerOptions.filter((f) =>
        f.label.toLowerCase().includes(newValue.toLowerCase())
      );
      setData(filteredOptions);
    };

    const handleChange = (newValue) => {
      setValue(newValue);
      form.setFieldsValue({
        farmer_id: newValue,
      });
    };

    return (
      <Select
        showSearch
        value={value}
        placeholder={props.placeholder}
        style={props.style}
        defaultActiveFirstOption={false}
        suffixIcon={null}
        filterOption={false}
        onSearch={handleSearch}
        onChange={handleChange}
        notFoundContent={null}
        options={data}
        size="large"
      />
    );
  };

  return (
    <>
      <Modal title="" open={newDialog} onCancel={onDialogClose} footer={[]}>
        {contextHolder}
        <h2 className="text-2xl text-center mb-4">
          {Object.values(selectedMaterial).length > 0
            ? "Edit Material"
            : "Add Material"}
        </h2>
        <Form
          form={form}
          initialValues={{
            farmer_id: "",
            material_type: "",
            material_quantity: "",
            remember: false,
          }}
          onFinish={onFinish}
          autoComplete="off">
          <Row>
            <Col span={12} style={style}>
              <Typography>Farmer</Typography>
              <Form.Item
                hasFeedback
                name="farmer_id"
                rules={[
                  {
                    required: true,
                    message: "Required",
                  },
                ]}>
                <SearchInput placeholder="Select Farmer" />
              </Form.Item>
            </Col>
            <Col span={12} style={style}>
              <Typography>Type</Typography>
              <Form.Item
                name="material_type"
                rules={[
                  {
                    required: true,
                    message: "Required",
                  },
                ]}
                hasFeedback>
                <Select
                  placeholder="Select Type"
                  options={materialOption}
                  size="large"
                />
              </Form.Item>
            </Col>
            <Col span={12} style={style}>
              <Typography>Quantity</Typography>
              <Form.Item
                name="material_quantity"
                rules={[
                  {
                    required: true,
                    message: "Required",
                  },
                ]}
                hasFeedback>
                <Input size="large" placeholder="Quantity" type="number" />
              </Form.Item>
            </Col>
          </Row>
          <div className="flex justify-end items-center gap-2 mt-5 ">
            <Button key="back" onClick={onDialogClose}>
              Cancel
            </Button>
            <Button
              key="submit"
              type="primary"
              className="btn"
              htmlType="submit"
              loading={loading}>
              {Object.values(selectedMaterial).length > 0 ? "Update" : "Submit"}
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
};
export default MaterialForm;
