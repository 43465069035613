import React from "react";
import FarmerTableSearch from "./farmerTableSearch";
import { FiPlus } from "react-icons/fi";
import { Button, Flex, Tooltip } from "antd";
import { useDispatch } from "react-redux";
import { toggleNewDialog, setSelectedFarmer } from "../store/stateSlice";
import { GiFarmer } from "react-icons/gi";

const FarmerTableTools = () => {
  const dispatch = useDispatch();
  const onAdd = () => {
    dispatch(setSelectedFarmer({}));
    dispatch(toggleNewDialog(true));
  };

  return (
    <Flex gap={"large"}>
      <FarmerTableSearch />
      <Tooltip title="Add Farmer" color={"magenta"} key={"magenta"}>
        <Button
          className="btn"
          type="primary"
          shape="circle"
          icon={<GiFarmer />}
          size="large"
          onClick={onAdd}
        />
      </Tooltip>
    </Flex>
  );
};

export default FarmerTableTools;
