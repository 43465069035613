import React from "react";
import { injectReducer } from "../../store/index.js";
import saudaReducer from "./store";
import Card from "antd/es/card/Card.js";
import SaudaTable from "./components/saudaTable.js";
import SaudaForm from "./components/saudaForm.js";

injectReducer("createSauda", saudaReducer);

const Sauda = () => {
  return (
    <Card className="page-content">
      <div className="lg:flex items-center justify-between mb-4">
        <h3 className="mb-4 lg:mb-0 text-3xl  ">Sauda</h3>
      </div>
      <SaudaForm />
      <SaudaTable />
    </Card>
  );
};
export default Sauda;
