import React, { useState, useEffect, useCallback } from "react";
import { Table, Popconfirm, notification } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { HiOutlinePencil, HiOutlineTrash } from "react-icons/hi";
import {
  getAllBuyer,
  setBuyerTableData,
  deleteBuyer,
} from "../store/dataSlice.js";
import { setSelectedBuyer, toggleNewDialog } from "../store/stateSlice.js";
import BuyerForm from "./buyerForm.js";

const ActionColumn = ({ record }) => {
  const dispatch = useDispatch();
  const [api, contextHolder] = notification.useNotification();
  const confirm = async (e) => {
    try {
      const action = await dispatch(deleteBuyer({ buyer_id: record.buyer_id }));
      if (action.payload.status < 300) {
        api["success"]({
          message: "Success",
          description: "Buyer Deleted Successfully",
        });

        dispatch(getAllBuyer());
      } else {
        api["error"]({
          message: "Error",
          description: action?.payload?.data?.message,
        });
      }
    } catch {}
  };
  const cancel = (e) => {};

  const onEdit = () => {
    dispatch(setSelectedBuyer(record));
    dispatch(toggleNewDialog(true));
  };

  return (
    <div className="flex text-lg">
      {contextHolder}
      <span
        className={`cursor-pointer p-2 hover:text-green-500`}
        onClick={onEdit}>
        <HiOutlinePencil />
      </span>
      <Popconfirm
        title="Delete Buyer"
        placement="topLeft"
        description="Are you sure to delete this Buyer"
        onConfirm={confirm}
        onCancel={cancel}
        okText="Yes"
        okButtonProps={{
          style: { background: "blue", color: "white" },
        }}
        cancelText="No">
        <span className="cursor-pointer p-2 hover:text-red-500">
          <HiOutlineTrash />
        </span>
      </Popconfirm>
    </div>
  );
};

const columns = [
  {
    title: "ID",
    dataIndex: "buyer_id",
    render: (text) => <a>#{text}</a>,
  },
  {
    title: "Name",
    dataIndex: "buyer_name",
    render: (text) => <a>{text}</a>,
  },
  {
    title: "Address",
    dataIndex: "buyer_address",
  },
  {
    title: "Contact",
    dataIndex: "buyer_mobile",
  },
  {
    title: "Action",
    dataIndex: "",
    render: (text, record) => <ActionColumn record={record} />,
  },
];

const BuyerTable = () => {
  const dispatch = useDispatch();

  const data = useSelector((state) => state.buyer.data.buyers);

  const loading = useSelector((state) => state.buyer.data.loading);

  const { pageIndex, pageSize, query, total, sort } = useSelector(
    (state) => state.buyer.data.tableData
  );

  const fetchData = useCallback(() => {
    dispatch(getAllBuyer({ pageIndex, pageSize, query, sort }));
  }, [pageIndex, pageSize, query, sort]);

  useEffect(() => {
    fetchData();
  }, [pageIndex, pageSize, query, sort]);

  const handleTableChange = (pagination, filters, sorter, extra) => {
    const { order, field } = sorter;
    console.log(extra);
    const { current, pageSize } = pagination;
    dispatch(
      setBuyerTableData({
        pageIndex: current,
        pageSize,
        sort: {
          order,
          field,
        },
      })
    );
  };
  return (
    <>
      <Table
        columns={columns}
        dataSource={data}
        rowKey={(row) => row.buyer_id}
        scroll={{
          x: 1000,
        }}
        bordered
        pagination={{ current: pageIndex, pageSize: pageSize }}
        loading={loading}
        sorter={sort}
        onChange={handleTableChange}
      />
      <BuyerForm />
    </>
  );
};

export default BuyerTable;
