import React from "react";
import { injectReducer } from "../../store/index.js";
import farmerReducer from "./store/index";
import FarmerTable from "./components/Tablelist.js";
import FarmerTableTools from "./components/farmerTableTools.js";
import Card from "antd/es/card/Card.js";

injectReducer("farmer", farmerReducer);

const Farmer = () => {
  return (
    <Card className="page-content">
      <div className="lg:flex items-center justify-between mb-4">
        <h3 className="mb-4 lg:mb-0 text-3xl  ">Farmer</h3>
        <FarmerTableTools />
      </div>
      <FarmerTable />
    </Card>
  );
};
export default Farmer;
