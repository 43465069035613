import ApiService from "./ApiService";

export async function apiGetAllBuyer(params) {
  return ApiService.fetchData({
    url: "/buyer",
    method: "get",
    params,
  });
}

export async function apiGetAllBuyerOrders() {
  return ApiService.fetchData({
    url: "buyer/sauda/details/id",
    method: "get",
  });
}

export async function apiGetAllBuyerOrdersByDate(data) {
  return ApiService.fetchData({
    url: "/buyer/all/sauda/details/id",
    method: "post",
    data,
  });
}

export async function apiGetBuyerDetails(data) {
  return ApiService.fetchData({
    url: "/buyer/sauda/all/by/dates",
    method: "post",
    data,
  });
}

export async function apiDeleteBuyer(data) {
  return ApiService.fetchData({
    url: "/buyer/delete/id",
    method: "delete",
    data,
  });
}

export async function apipostBuyer(data) {
  return ApiService.fetchData({
    url: "/buyer/register",
    method: "post",
    data,
  });
}

export async function apiputBuyer(data) {
  return ApiService.fetchData({
    url: "/buyer/update/id",
    method: "put",
    data,
  });
}

export async function apigetBuyerOptions(data) {
  return ApiService.fetchData({
    url: "/buyer/option",
    method: "get",
    data,
  });
}
