import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  apiGetAllBuyer,
  apiDeleteBuyer,
  apipostBuyer,
  apiputBuyer,
  apiGetAllBuyerOrders,
  apiGetBuyerDetails,
  apiGetAllBuyerOrdersByDate,
} from "../../../services/BuyerService";

export const getAllBuyer = createAsyncThunk("buyer/data/get", async (data) => {
  try {
    const response = await apiGetAllBuyer(data);
    return response;
  } catch (error) {
    return error?.response || error.toString();
  }
});

export const deleteBuyer = createAsyncThunk(
  "buyer/data/delete",
  async (data) => {
    try {
      const response = await apiDeleteBuyer(data);
      return response;
    } catch (error) {
      return error?.response || error.toString();
    }
  }
);

export const postBuyer = createAsyncThunk("buyer/data/post", async (data) => {
  try {
    const response = await apipostBuyer(data);
    return response;
  } catch (error) {
    return error?.response || error.toString();
  }
});

export const putBuyer = createAsyncThunk("buyer/data/put", async (data) => {
  try {
    const response = await apiputBuyer(data);
    return response;
  } catch (error) {
    return error?.response || error.toString();
  }
});

export const getBuyerOrders = createAsyncThunk(
  "buyer/orders/get",
  async (data) => {
    try {
      const response = await apiGetAllBuyerOrders(data);
      return response;
    } catch (error) {
      return error?.response || error.toString();
    }
  }
);

export const getOrdersWithDateId = createAsyncThunk(
  "buyer/orders/details/get",
  async (data) => {
    try {
      const response = await apiGetAllBuyerOrdersByDate(data);
      return response;
    } catch (error) {
      return error?.response || error.toString();
    }
  }
);

export const getBuyerDetails = createAsyncThunk(
  "buyer/details/get",
  async (data) => {
    try {
      const response = await apiGetBuyerDetails(data);
      return response;
    } catch (error) {
      return error?.response || error.toString();
    }
  }
);

export const initialTableData = {
  total: 0,
  pageIndex: 1,
  pageSize: 10,
  query: "",
  sort: {
    order: "",
    field: "",
  },
};

const dataSlice = createSlice({
  name: "buyer/data",
  initialState: {
    loading: false,
    buyers: [],
    tableData: initialTableData,
  },
  reducers: {
    setBuyerOrderList: (state, action) => {
      state.buyers = action.payload;
    },
    setBuyerTableData: (state, action) => {
      state.tableData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllBuyer.fulfilled, (state, action) => {
        state.buyers = action.payload?.data?.data || [];
        state.tableData.total = action.payload?.data?.total || 0;
        state.loading = false;
      })
      .addCase(getAllBuyer.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllBuyer.rejected, (state) => {
        state.loading = false;
        state.buyers = [];
        state.tableData.total = 0;
      })
      .addCase(deleteBuyer.fulfilled, (state) => {})
      .addCase(postBuyer.fulfilled, (state) => {})
      .addCase(putBuyer.fulfilled, (state) => {})
      .addCase(getBuyerOrders.fulfilled, (state) => {})
      .addCase(getBuyerDetails.fulfilled, (state) => {})
      .addCase(getOrdersWithDateId.fulfilled, (state) => {});
  },
});

export const { setOrderList, setBuyerTableData } = dataSlice.actions;

export default dataSlice.reducer;
