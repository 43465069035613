import axios from "axios";
// import appConfig from '../configs/app.config'
// import { TOKEN_TYPE, REQUEST_HEADER_AUTH_KEY } from '../constants/api.constant'
// import { PERSIST_STORE_NAME } from '../constants/app.constant'
// import { onSignOutSuccess } from '../store/auth/sessionSlice'

const unauthorizedCode = [401];

const BaseService = axios.create({
  timeout: 60000,
  baseURL: "https://auction.5techg.com/api/v1/web",
});

BaseService.interceptors.request.use(
  (config) => {
    const rawPersistData = localStorage.getItem("user");
    const persistData = JSON.parse(rawPersistData);

    let accessToken = persistData ? persistData.token : null;

    // if (!accessToken) {
    //     const { auth } = store.getState()
    //     accessToken = auth.session.token
    // }

    if (accessToken) {
      config.headers["Authorization"] = `${"Bearer "}${accessToken}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

BaseService.interceptors.response.use(
  (response) => response,
  (error) => {
    const { response } = error;

    // if (response && unauthorizedCode.includes(response.status)) {
    //   store.dispatch(onSignOutSuccess());
    // }

    return Promise.reject(error);
  }
);

export default BaseService;
