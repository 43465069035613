import React, { useRef } from "react";
import { HiOutlineSearch } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import debounce from "lodash/debounce";
import cloneDeep from "lodash/cloneDeep";
import { getAllMaterial, setTableData } from "../store/dataSlice";
import { Input } from "antd";

const MaterialTableSearch = () => {
  const dispatch = useDispatch();

  const searchInput = useRef();

  const tableData = useSelector((state) => state.material.data.tableData);

  const debounceFn = debounce(handleDebounceFn, 500);

  function handleDebounceFn(val) {
    const newTableData = cloneDeep(tableData);
    newTableData.query = val;
    newTableData.pageIndex = 1;
    if (typeof val === "string" && val.length > 1) {
      fetchData(newTableData);
    }

    if (typeof val === "string" && val.length === 0) {
      fetchData(newTableData);
    }
  }

  const fetchData = (data) => {
    dispatch(getAllMaterial(data));
    dispatch(setTableData(data));
  };

  const onEdit = (e) => {
    debounceFn(e.target.value);
  };

  return (
    <Input
      ref={searchInput}
      placeholder="Search Material"
      size="large"
      prefix={<HiOutlineSearch className="site-form-item-icon" />}
      onChange={onEdit}
    />
  );
};

export default MaterialTableSearch;
