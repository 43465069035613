import { InputNumber, ConfigProvider } from "antd";
import React, { useState } from "react";
import {
  toggleSaudaIdPrice,
  toggleSetMaterialPrice,
} from "../store/stateSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

const BuyerPriceInput = ({ row }) => {
  const dispatch = useDispatch();

  const [buyerPrice, setBuyerPrice] = useState("");
  const handleChange = (e) => {
    // setBuyerPrice(e);
    // row.material_price = e.targer.value;
    dispatch(toggleSaudaIdPrice(row?.sauda_id));
    dispatch(toggleSetMaterialPrice(e));
    setBuyerPrice(e);
  };

  useEffect(() => {
    if (row.material_price) {
      setBuyerPrice(row.material_price);
    }
  }, []);

  return (
    <ConfigProvider
      theme={{
        components: {
          InputNumber: {
            handleWidth: "0",
          },
        },
      }}>
      <InputNumber value={buyerPrice} onChange={handleChange} />
    </ConfigProvider>
  );
};

export default BuyerPriceInput;
