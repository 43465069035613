import React from "react";
import MaterialTableSearch from "./materialTableSearch";
import { Button, Flex, Tooltip } from "antd";
import { useDispatch } from "react-redux";
import { toggleNewDialog, setSelectedMaterial } from "../store/stateSlice";
import { FaPlantWilt } from "react-icons/fa6";

const MaterialTableTools = () => {
  const dispatch = useDispatch();
  const onAdd = () => {
    dispatch(setSelectedMaterial({}));
    dispatch(toggleNewDialog(true));
  };

  return (
    <Flex gap={"large"}>
      <MaterialTableSearch />
      <Tooltip title="Add Material" color={"magenta"} key={"magenta"}>
        <Button
          className="btn"
          type="primary"
          shape="circle"
          icon={<FaPlantWilt />}
          size="large"
          onClick={onAdd}
        />
      </Tooltip>
    </Flex>
  );
};

export default MaterialTableTools;
