import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Farmer from "../../view/farmer_management";
import Logout from "../../view/login/Logout";
import Buyer from "../../view/buyer_management";
import BuyerOrders from "../../view/buyer_management/components/buyerOrders.js";
import Material from "../../view/material_list";
import Sauda from "../../view/saudha";
import SaudaList from "../../view/sauda_list/index";
import SaudaTable from "../../view/sauda_list/components/saudaTable.js";
import ViewPage from "../../view/buyer_management/components/ViewPage.js";
import BuyerOrderWithDate from "../../view/buyer_management/components/buyerOrderWithDate.js";
const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/farmers" element={<Farmer />}></Route>
      <Route path="/buyers" element={<Buyer />}></Route>
      <Route path="/buyer/orders" element={<BuyerOrders />}></Route>
      <Route path="/buyer-list/:buyer" element={<ViewPage />}></Route>
      <Route
        path="/buyer-list/:buyer/:date"
        element={<BuyerOrderWithDate />}></Route>

      <Route path="/mateiral" element={<Material />}></Route>
      <Route path="/logout" element={<Logout />}></Route>
      <Route path="/sauda/add" element={<Sauda />}></Route>
      <Route path="/sauda/allsauda" element={<SaudaList />}></Route>
      <Route path="/sauda-list/:date" element={<SaudaTable />}></Route>
    </Routes>
  );
};

export default AppRoutes;
