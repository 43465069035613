import { createSlice } from "@reduxjs/toolkit";

const stateSlice = createSlice({
  name: "farmer/state",
  initialState: {
    deleteConfirmation: false,
    openDialog: false,
    editDialog: false,
    selectedFarmer: {},
  },
  reducers: {
    toggleDeleteConfirmation: (state, action) => {
      state.deleteConfirmation = action.payload;
    },
    toggleNewDialog: (state, action) => {
      state.openDialog = action.payload;
    },
    toggleEditDialog: (state, action) => {
      state.editDialog = action.payload;
    },
    setSelectedFarmer: (state, action) => {
      state.selectedFarmer = action.payload;
    },
  },
});

export const {
  toggleDeleteConfirmation,
  toggleNewDialog,
  setSelectedFarmer,
  toggleEditDialog,
} = stateSlice.actions;

export default stateSlice.reducer;
