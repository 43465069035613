import React from "react";
import BuyerTableSearch from "./buyerTableSearch";
import { Button, Flex, Tooltip } from "antd";
import { useDispatch } from "react-redux";
import { toggleNewDialog, setSelectedBuyer } from "../store/stateSlice";
import { FaRegCreditCard } from "react-icons/fa";

const BuyerTableTools = () => {
  const dispatch = useDispatch();
  const onAdd = () => {
    dispatch(setSelectedBuyer({}));
    dispatch(toggleNewDialog(true));
  };

  return (
    <Flex gap={"large"}>
      <BuyerTableSearch />
      <Tooltip title="Add Buyer" color={"magenta"} key={"magenta"}>
        <Button
          className="btn"
          type="primary"
          shape="circle"
          icon={<FaRegCreditCard />}
          size="large"
          onClick={onAdd}
        />
      </Tooltip>
    </Flex>
  );
};

export default BuyerTableTools;
