import React, { useState, useCallback, useEffect } from "react";
import { Card, Table, Flex, Button } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getBuyerDetails } from "../store/dataSlice";

const ViewPage = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const [bName, setBName] = useState("");
  const [bId, setBId] = useState("");
  const { buyer } = useParams();
  //   console.log(data);

  const fetchData = useCallback(() => {
    setLoading(true);
    dispatch(
      getBuyerDetails({
        buyer_id: buyer,
      })
    )
      .then((response) => {
        console.log(response.payload.data.data);
        setData(response.payload.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (data) {
      setBName(data[0]["Buyer.buyer_name"]);
      setBId(data[0]["Buyer.buyer_id"]);
      // console.log(data[0]["Buyer.buyer_name"]);
    }
  }, [data]);

  useEffect(() => {
    fetchData();
  }, []);

  const columns = [
    {
      title: "Date",
      dataIndex: "sauda_date",
      key: "sauda_date",
    },
    {
      title: "Total Orders",
      dataIndex: "orders",
      key: "orders",
    },
    {
      title: "Confirmed Orders",
      dataIndex: "confirmed_orders",
      key: "confirmed_orders",
    },
    {
      title: "Action",
      render: (_, record) => (
        <Button
          size="small"
          type="primary"
          className="btn"
          onClick={() => {
            navigate(
              `/buyer-list/${record["Buyer.buyer_id"]}/${record.sauda_date}`
            );
          }}>
          View
        </Button>
      ),
    },
  ];

  return (
    <Card className="page-content">
      <div className="lg:flex items-center justify-between mb-4">
        <h3 className="mb-4 lg:mb-0 text-3xl">
          Orders Of {bName} with buyer Id: {bId}
        </h3>
      </div>
      <Flex
        justify="flex-end"
        style={{ marginBottom: "2rem", marginRight: "1rem" }}>
        <Button
          size="small"
          className="btn"
          type="primary"
          onClick={() => navigate(`/buyer/orders`)}>
          Go Back
        </Button>
      </Flex>

      <Table
        rowKey={(row) => row.sauda_date}
        columns={columns}
        dataSource={data}
        scroll={{
          x: 1000,
        }}
        bordered
        loading={loading}

        // onChange={handleTableChange}
      />
    </Card>
  );
};

export default ViewPage;
