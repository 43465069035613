import { combineReducers } from "@reduxjs/toolkit";
import data from "./dataSlice";
import state from "./stateSlice";

const saudaReducer = combineReducers({
  data,
  state,
});

export default saudaReducer;
