import {
  createSlice,
  createAsyncThunk,
  configureStore,
} from "@reduxjs/toolkit";

import {
  apigetFarmerOptions,
  apiPostFarmer,
} from "../../../services/FarmerService";
import { apiPostSauda, apiPostSaudaList } from "../../../services/SaudaService";

export const postSaudaList = createAsyncThunk(
  "sauda/data/post",
  async (data) => {
    try {
      const response = await apiPostSauda(data);
      return response;
    } catch (error) {
      return error?.response || error.toString();
    }
  }
);

export const initialTableData = {
  total: 0,
  pageIndex: 1,
  pageSize: 10,
  query: "",
  sort: {
    order: "",
    field: "",
  },
};

export const getFarmerOption = createAsyncThunk(
  "farmer/data/option",
  async (data) => {
    try {
      const response = await apigetFarmerOptions(data);
      return response;
    } catch (error) {
      return error?.response || error.toString();
    }
  }
);

export const postFarmer = createAsyncThunk("farmer/data/post", async (data) => {
  try {
    const response = await apiPostFarmer(data);
    return response;
  } catch (error) {
    return error?.response || error.toString();
  }
});

const dataSlice = createSlice({
  name: "sauda/data",
  initialState: {
    loading: false,
    sauda: [],
    tableData: initialTableData,
  },
  reducers: {
    setOrderList: (state, action) => {
      state.sauda = action.payload;
    },
    setTableData: (state, action) => {
      state.tableData = action.payload;
    },
    setSaudaDataDelete: (state, action) => {
      const indexToDelete = action.payload;
      state.sauda.splice(indexToDelete, 1);
    },

    setSaudaData: (state, action) => {
      // state.saudaData = action.payload;
      state.sauda = [...state.sauda, action.payload];

      // console.log(action.payload);
    },

    setSaudaDataUpdate: (state, action) => {
      const { data, index } = action.payload;

      // find row with farmer id and buyer id
      // const indexInState = state.sauda.findIndex(s => s.farmer_id===data.farmer_id && s.buyer_id===data.buyer_id )

      const newState = [...state.sauda];
      newState[index] = data;

      state.sauda = newState;
    },
  },

  extraReducers: (builder) => {
    builder

      .addCase(getFarmerOption.fulfilled, (state, action) => {
        state.farmers = action.payload?.data?.data || [];
      })
      .addCase(getFarmerOption.pending, (state, action) => {
        state.farmers = [];
      })
      .addCase(getFarmerOption.rejected, (state, action) => {
        state.farmers = [];
      })

      .addCase(postSaudaList.fulfilled, (state, action) => {});
  },
});

export const {
  setOrderList,
  setTableData,
  setSaudaDataDelete,
  setSaudaData,
  setSaudaDataUpdate,
} = dataSlice.actions;

export default dataSlice.reducer;
