import { createSlice } from "@reduxjs/toolkit";

const stateSlice = createSlice({
  name: "material/state",
  initialState: {
    deleteConfirmation: false,
    openDialog: false,
    selectedMaterial: {},
  },
  reducers: {
    toggleDeleteConfirmation: (state, action) => {
      state.deleteConfirmation = action.payload;
    },
    toggleNewDialog: (state, action) => {
      state.openDialog = action.payload;
    },
    setSelectedMaterial: (state, action) => {
      state.selectedMaterial = action.payload;
    },
  },
});

export const {
  toggleDeleteConfirmation,
  toggleNewDialog,
  setSelectedMaterial,
} = stateSlice.actions;

export default stateSlice.reducer;
