import React, { useEffect } from "react";

const Logout = () => {
  useEffect(() => {
    localStorage.removeItem("user");
    window.location.href = "/login";
  }, []);

  return "";
};
export default Logout;
