import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getOrdersWithDateId } from "../store/dataSlice";
import { Button, Card, Flex, Table } from "antd";

const BuyerOrderWithDate = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const { buyer, date } = useParams();

  const fetchData = useCallback(() => {
    setLoading(true);
    dispatch(
      getOrdersWithDateId({
        buyer_id: buyer,
        sauda_date: date,
      })
    )
      .then((response) => {
        // console.log(response.payload.data.data);
        setData(response.payload.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    fetchData();
  }, []);
  console.log(data);

  const columns = [
    {
      title: "Sauda ID",
      dataIndex: "sauda_id",
      key: "sauda_id",
    },
    {
      title: "Farmer Name",
      dataIndex: "Farmer.farmer_name",
      key: "farmer_name",
      render: (text, record) => record.Farmer.farmer_name,
    },
    {
      title: "Farmer Id",
      dataIndex: "Farmer.farmer_id",
      key: "farmer_id",
      render: (text, record) => record.Farmer.farmer_id,
    },
    {
      title: "AWK No.",
      dataIndex: "awk_number",
      key: "awk_number",
    },
    {
      title: "Type",
      dataIndex: "material_type",
      key: "material_type",
    },
    {
      title: "Quantity",
      dataIndex: "material_quantity",
      key: "material_quantity",
    },
    {
      title: "Price",
      dataIndex: "material_price",
      key: "material_price",
    },
    {
      title: "Confirmation",
      dataIndex: "sauda_confirmation",
      key: "sauda_confirmation",
      render: (row) =>
        row.sauda_confirmation === "confirm" ? "Confirmed" : "Not-Confirmed",
    },
  ];

  return (
    <Card className="page-content">
      <div className="lg:flex items-center justify-between mb-4">
        <h3 className="mb-4 lg:mb-0 text-3xl">Orders for date: {date}</h3>
      </div>
      <Flex
        justify="flex-end"
        style={{ marginBottom: "2rem", marginRight: "1rem" }}>
        <Button
          size="small"
          className="btn"
          type="primary"
          onClick={() => navigate(`/buyer-list/${buyer}`)}>
          Go Back
        </Button>
      </Flex>

      <Table
        rowKey={(row) => row.sauda_id}
        columns={columns}
        dataSource={data}
        scroll={{
          x: 1000,
        }}
        bordered
        loading={loading}

        // onChange={handleTableChange}
      />
    </Card>
  );
};

export default BuyerOrderWithDate;
