import React, { useEffect, useState } from "react";
// import { Field, Form, Formik } from "formik";
import {
  Modal,
  Form,
  Input,
  Button,
  Col,
  Row,
  Typography,
  notification,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { toggleNewDialog, setSelectedFarmer } from "../store/stateSlice";
import { postFarmer, getAllFarmer, putFarmer } from "../store/dataSlice";

const style = {
  padding: "0px 10px",
};

const FarmerForm = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(null);
  const [api, contextHolder] = notification.useNotification();

  const newDialog = useSelector((state) => state.farmer.state.openDialog);
  const selectedFarmer = useSelector(
    (state) => state.farmer.state.selectedFarmer
  );

  const onDialogClose = () => {
    dispatch(toggleNewDialog(false));
    dispatch(setSelectedFarmer({}));
    form.resetFields();
  };
  const [form] = Form.useForm();

  useEffect(() => {
    if (selectedFarmer) {
      form.resetFields();
      form.setFieldsValue(selectedFarmer);
    } else {
      form.resetFields();
      form.setFieldsValue({
        farmer_name: "",
        farmer_mobile: "",
        farmer_address: "",
        farmer_account_no: "",
        farmer_ifsc_code: "",
        farmer_bank_name: "",
        remember: false,
      });
    }
  }, [selectedFarmer, form]);

  const onFinish = async (values) => {
    try {
      setLoading(true);
      const action =
        Object.values(selectedFarmer).length > 0
          ? await dispatch(
              putFarmer({ ...values, farmer_id: selectedFarmer.farmer_id })
            )
          : await dispatch(postFarmer(values));
      if (action.payload.status < 300) {
        api["success"]({
          message: "Success",
          description:
            Object.values(selectedFarmer).length > 0
              ? "Farmer Edited Successfully"
              : "Farmer Added Successfully",
        });
        setLoading(false);
        dispatch(toggleNewDialog(false));
        dispatch(getAllFarmer());
        form.resetFields();
      } else {
        api["error"]({
          message: "Error",
          description: action?.payload?.data?.message,
        });
        setLoading(false);
      }
    } catch (err) {}
  };

  return (
    <>
      <Modal title="" open={newDialog} onCancel={onDialogClose} footer={[]}>
        {contextHolder}
        <h2 className="text-2xl text-center mb-4">
          {Object.values(selectedFarmer).length > 0
            ? "Edit Farmer"
            : "Add Farmer"}
        </h2>
        <Form
          form={form}
          initialValues={{
            farmer_name: "",
            farmer_mobile: "",
            farmer_address: "",
            farmer_account_no: "",
            farmer_ifsc_code: "",
            farmer_bank_name: "",
            remember: false,
          }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Row>
            <Col span={12} style={style}>
              <Typography>Name</Typography>
              <Form.Item
                name="farmer_name"
                rules={[
                  {
                    required: true,
                    message: "Required",
                  },
                ]}
                className="gap-2"
                hasFeedback
              >
                <Input size="large" placeholder="farmer_name" />
              </Form.Item>
            </Col>
            <Col span={12} style={style}>
              <Typography>Contact</Typography>
              <Form.Item
                name="farmer_mobile"
                rules={[
                  {
                    required: true,
                    message: "Required",
                  },
                ]}
                hasFeedback
              >
                <Input size="large" placeholder="Farmer_mobile" type="number" />
              </Form.Item>
            </Col>
            <Col span={12} style={style}>
              <Typography>Address</Typography>
              <Form.Item
                name="farmer_address"
                rules={[
                  {
                    required: true,
                    message: "Required",
                  },
                ]}
                hasFeedback
              >
                <Input size="large" placeholder="farmer_address" />
              </Form.Item>
            </Col>
            <Col span={12} style={style}>
              <Typography>Account No.</Typography>
              <Form.Item
                name="farmer_account_no"
                rules={[
                  {
                    required: true,
                    message: "Required",
                  },
                ]}
                hasFeedback
              >
                <Input size="large" placeholder="Account Number" />
              </Form.Item>
            </Col>
            <Col span={12} style={style}>
              <Typography>IFSC</Typography>
              <Form.Item
                name="farmer_ifsc_code"
                rules={[
                  {
                    required: true,
                    message: "Required",
                  },
                ]}
                hasFeedback
              >
                <Input size="large" placeholder="IFSC Code" />
              </Form.Item>
            </Col>
            <Col span={12} style={style}>
              <Typography>Bank</Typography>
              <Form.Item
                name="farmer_bank_name"
                rules={[
                  {
                    required: true,
                    message: "Required",
                  },
                ]}
                hasFeedback
              >
                <Input size="large" placeholder="Bank Name" />
              </Form.Item>
            </Col>
          </Row>
          <div className="flex justify-end items-center gap-2 mt-5 ">
            <Button key="back" onClick={onDialogClose}>
              Cancel
            </Button>
            <Button
              key="submit"
              type="primary"
              className="btn"
              htmlType="submit"
              loading={loading}
            >
              {Object.values(selectedFarmer).length > 0 ? "Update" : "Submit"}
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
};
export default FarmerForm;
