import React, { useState, useEffect, useCallback } from "react";
import { Table, Popconfirm, notification } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { HiOutlinePencil, HiOutlineTrash } from "react-icons/hi";
import {
  getAllFarmer,
  setTableData,
  deleteFarmer,
} from "../store/dataSlice.js";
import {
  setSelectedFarmer,
  toggleNewDialog,
  toggleEditDialog,
} from "../store/stateSlice.js";
import FarmerForm from "./farmerForm.js";

const ActionColumn = ({ record }) => {
  const dispatch = useDispatch();
  const [api, contextHolder] = notification.useNotification();
  const confirm = async (e) => {
    try {
      const action = await dispatch(
        deleteFarmer({ farmer_id: record.farmer_id })
      );
      if (action.payload.status < 300) {
        api["success"]({
          message: "Success",
          description: "Farmer Deleted Successfully",
        });

        dispatch(getAllFarmer());
      } else {
        api["error"]({
          message: "Error",
          description: action?.payload?.data?.message,
        });
      }
    } catch {}
  };
  const cancel = (e) => {};

  const onEdit = () => {
    dispatch(setSelectedFarmer(record));
    dispatch(toggleNewDialog(true));
  };

  return (
    <div className="flex text-lg">
      {contextHolder}
      <span
        className={`cursor-pointer p-2 hover:text-green-500`}
        onClick={onEdit}
      >
        <HiOutlinePencil />
      </span>
      <Popconfirm
        title="Delete Farmer"
        placement="topLeft"
        description="Are you sure to delete this Farmer"
        onConfirm={confirm}
        onCancel={cancel}
        okText="Yes"
        okButtonProps={{
          style: { background: "blue", color: "white" },
        }}
        cancelText="No"
      >
        <span className="cursor-pointer p-2 hover:text-red-500">
          <HiOutlineTrash />
        </span>
      </Popconfirm>
    </div>
  );
};

const columns = [
  {
    title: "ID",
    dataIndex: "farmer_id",
    sorter: true,
    render: (text) => <a>#{text}</a>,
  },
  {
    title: "Name",
    dataIndex: "farmer_name",
    sorter: true,
    render: (text) => <a>{text}</a>,
  },
  {
    title: "Address",
    className: "column-money",
    dataIndex: "farmer_address",
    sorter: true,
  },
  {
    title: "Contact",
    className: "column-money",
    dataIndex: "farmer_mobile",
    sorter: true,
  },
  {
    title: "Action",
    dataIndex: "",
    render: (text, record) => <ActionColumn record={record} />,
  },
];

const FarmerTable = () => {
  const dispatch = useDispatch();

  const data = useSelector((state) => state.farmer.data.farmers);
  const loading = useSelector((state) => state.farmer.data.loading);
  console.log(loading);

  const { pageIndex, pageSize, query, total, sort } = useSelector(
    (state) => state.farmer.data.tableData
  );

  const fetchData = useCallback(() => {
    dispatch(getAllFarmer({ pageIndex, pageSize, query, sort }));
  }, [pageIndex, pageSize, query, sort]);

  useEffect(() => {
    fetchData();
  }, [pageIndex, pageSize, query, sort]);

  const handleTableChange = (pagination, filters, sorter, extra) => {
    const { order, field } = sorter;
    console.log(extra);
    const { current, pageSize } = pagination;
    dispatch(
      setTableData({
        pageIndex: current,
        pageSize,
        sort: {
          order,
          field,
        },
      })
    );
  };
  return (
    <>
      <Table
        columns={columns}
        dataSource={data}
        scroll={{
          x: 1000,
        }}
        bordered
        pagination={{ current: pageIndex, pageSize: pageSize }}
        loading={loading}
        sorter={sort}
        onChange={handleTableChange}
      />
      <FarmerForm />
      {/* <FarmerFormEdit /> */}
    </>
  );
};

export default FarmerTable;
