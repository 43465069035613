import React, { useState, useEffect, useCallback } from "react";
import { Table, Popconfirm, notification, Dropdown, Menu, Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { HiOutlinePencil, HiOutlineTrash } from "react-icons/hi";
import {
  setSaudaDataDelete,
  postSaudaList,
  setSaudaDataUpdate,
  setOrderList,
  setTableData,
} from "../store/dataSlice";

import { toggleSetDate } from "../store/stateSlice";

const ActionColumn = ({ record, index, disabled }) => {
  const dispatch = useDispatch();

  const confirm = async (e) => {
    dispatch(setSaudaDataDelete(index));
  };
  const cancel = (e) => {};

  return (
    <div className="flex text-lg">
      <Popconfirm
        title="Delete Creation"
        placement="topLeft"
        description="Are you sure to delete this Creation"
        onConfirm={confirm}
        onCancel={cancel}
        okText="Yes"
        okButtonProps={{
          style: { background: "blue", color: "white" },
        }}
        cancelText="No"
        disabled={disabled}
        className={disabled ? "disabled" : ""}>
        <span className="cursor-pointer p-2 hover:text-red-500">
          <HiOutlineTrash
            disabled={disabled}
            className={disabled ? "disabled" : ""}
          />
        </span>
      </Popconfirm>
    </div>
  );
};

// const ConfirmationDropdown = ({ initialValue, id, setConfirm, disabled }) => {
//   const [selectedValue, setSelectedValue] = useState(initialValue || "Select");

//   const handleMenuClick = (e) => {
//     setSelectedValue(e.key);
//     setConfirm((p) => ({ ...p, [id]: e.key }));
//   };

//   console.log(selectedValue);
//   const menu = (
//     <Menu onClick={handleMenuClick} selectedKeys={[selectedValue]}>
//       <Menu.Item key="confirm">Confirm</Menu.Item>
//       <Menu.Item key="not-confirm">Not Confirm</Menu.Item>
//     </Menu>
//   );

//   return (
//     <Dropdown overlay={menu} disabled={disabled}>
//       <Button
//         style={{
//           backgroundColor: "transparent",
//           // border: "none",
//           boxShadow: "none",
//           color: "inherit",
//           padding: "10px",
//           display: "flex",
//           alignItems: "center",
//           justifyContent: "center",
//           fontSize: "1rem",
//         }}
//         className={disabled ? "disabled" : ""}>
//         {selectedValue}
//       </Button>
//     </Dropdown>
//   );
// };

// const SaveButton = ({ ...props }) => {
//   return (
//     <button
//       {...props}
//       style={{
//         width: "80%",
//         padding: "8px 20px", // Adjust padding as needed
//         backgroundColor: "#4f6f52", // Background color
//         color: "#fff",
//         border: "none", // Remove default border
//         borderRadius: "6px", // Add border radius for rounded corners
//         cursor: "pointer",
//         display: "flex",
//         alignItems: "center",
//         justifyContent: "center",
//       }}
//       className={props.disabled ? "disabled" : ""}
//     />
//   );
// };

const SaudaTable = () => {
  const dispatch = useDispatch();

  const [api, contextHolder] = notification.useNotification();

  // const [disabledRows, setDisabledRows] = useState({});

  const farmers = useSelector((state) => state.farmer.data.farmers);
  const buyers = useSelector((state) => state.buyer.data.buyers);

  const [confirm, setConfirm] = useState({});
  const [SubmitListPop, setSubmitListPop] = useState(false);

  const saudaData = useSelector((state) => state.createSauda.data.sauda);

  const date = useSelector((state) => state.createSauda.state.date);
  console.log(date);

  const isConfirm = (record) => {
    return ["confirm", "not-confirm"].includes(record.material_confirm);
  };

  const columns = [
    {
      title: "Farmer",
      dataIndex: "farmer_id",
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      render: (id) => (
        <span>
          {farmers.find((f) => f.farmer_id === id)?.farmer_name || ""}
        </span>
      ),
    },
    {
      title: "AWK Number",
      dataIndex: "awk_number",
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Type",
      dataIndex: "material_type",
    },

    {
      title: "Quantity",
      dataIndex: "material_quantity",
    },
    // {
    //   title: "Buyer",
    //   dataIndex: "buyer_id",
    //   render: (id) => (
    //     <span>{buyers.find((f) => f.buyer_id === id)?.buyer_name || ""}</span>
    //   ),
    // },
    // {
    //   title: "Price",
    //   dataIndex: "material_price",
    // },

    // {
    //   title: "Confirmation",
    //   dataIndex: "material_confirm",
    //   render: (text, record, index) => (
    //     <ConfirmationDropdown
    //       initialValue={record.material_confirm}
    //       record={record}
    //       id={index}
    //       setConfirm={setConfirm}
    //       // disabled={disabledRows[index]}
    //       disabled={isConfirm(record)}
    //     />
    //   ),
    // },

    // {
    //   title: "Save",
    //   dataIndex: "material_save",
    //   render: (text, record, index) => (
    //     <SaveButton
    //       onClick={() => handleClick(record, index)}
    //       disabled={isConfirm(record)}>
    //       {/* Save {record.material_confirm ? "c" : "cn"} */}
    //       Save
    //     </SaveButton>
    //   ),
    // },
    {
      title: "Action",
      dataIndex: "",
      render: (text, record, index) => (
        <ActionColumn
          record={record}
          index={index}
          // disabled={disabledRows[index]}
          disabled={isConfirm(record)}
        />
      ),
    },
  ];

  // const handleClick = async (record, index) => {
  //   const dataToSend = {
  //     farmer_id: record.farmer_id,
  //     buyer_id: record.buyer_id,
  //     awk_number: record.material_awk,
  //     material_type: record.material_type,
  //     material_quantity: record.material_quantity,
  //     material_price: record.material_price,
  //     sauda_confirmation: confirm[index],
  //   };
  //   // setDisabledRows((prev) => ({ ...prev, [index]: true }));
  //   dispatch(postSauda(dataToSend));

  //   const dataToSend2 = { ...dataToSend };
  //   dataToSend2.material_awk = dataToSend2.awk_number;
  //   dataToSend2.material_confirm = dataToSend2.sauda_confirmation;

  //   delete dataToSend2.awk_number;
  //   delete dataToSend2.sauda_confirmation;

  //   dispatch(setSaudaDataUpdate({ data: dataToSend2, index }));

  //   console.log(dataToSend);
  // };
  console.log(saudaData);
  console.log(date);
  const onSubmit = async () => {
    try {
      // setLoading(true);
      const action = await dispatch(
        postSaudaList({
          data: saudaData,
          date,
        })
      );

      if (action.payload.status < 300) {
        api["success"]({
          message: "Success",
          description: "Sauda List Created Successfully.",
        });

        dispatch(toggleSetDate(""));
        dispatch(setOrderList([]));
        // setLoading(false);
        // dispatch(toggleNewDialog(false));
        // dispatch(getAllFarmer());
        // form.resetFields();
      } else {
        api["error"]({
          message: "Error",
          description: action?.payload?.data?.message,
        });
        // setLoading(false);
      }
    } catch (err) {
      api["error"]({
        message: "Error",
        description: err,
      });
    }

    setSubmitListPop(false);
  };

  const cancelListSubmit = () => {
    setSubmitListPop(false);
  };
  const confirmSubmit = () => {
    if (date) {
      setSubmitListPop(true);
    } else {
      api["error"]({
        message: "Error",
        description: `Please select Date for this Sauda List`,
      });
    }
  };
  const { pageIndex, pageSize, query, total, sort } = useSelector(
    (state) => state.sauda.data.tableData
  );

  const handleTableChange = (pagination, filters, sorter, extra) => {
    const { order, field } = sorter;
    console.log(extra);
    const { current, pageSize } = pagination;
    dispatch(
      setTableData({
        pageIndex: current,
        pageSize,
        sort: {
          order,
          field,
        },
      })
    );
  };

  return (
    <>
      {contextHolder}
      <Table
        rowKey={(row) => row.awk_number}
        columns={columns}
        dataSource={saudaData}
        scroll={{
          x: 1000,
        }}
        bordered
        onChange={handleTableChange}
        pagination={{ current: pageIndex, pageSize: pageSize }}
      />
      <Popconfirm
        title={`Confirm Sauda List For Date: ${date}`}
        placement="topLeft"
        onConfirm={onSubmit}
        onCancel={cancelListSubmit}
        okText="Yes, Submit"
        okButtonProps={{
          style: { background: "blue", color: "white" },
        }}
        cancelText="Cancel"
        open={SubmitListPop}>
        <Button className="btn" type="primary" onClick={confirmSubmit}>
          Submit Full List
        </Button>
      </Popconfirm>
      <style>
        {`
          .disabled {
            cursor: not-allowed !important;
          }
        `}
      </style>
    </>
  );
};

export default SaudaTable;
