import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  apiGetAllMaterial,
  apiDeleteMaterial,
  apipostMaterial,
  apiputMaterial,
  apigetFarmerOptions,
} from "../../../services/MaterialService";

export const getAllMaterial = createAsyncThunk(
  "material/data/get",
  async (data) => {
    try {
      const response = await apiGetAllMaterial(data);
      return response;
    } catch (error) {
      return error?.response || error.toString();
    }
  }
);

export const deleteMaterial = createAsyncThunk(
  "material/data/delete",
  async (data) => {
    try {
      const response = await apiDeleteMaterial(data);
      return response;
    } catch (error) {
      return error?.response || error.toString();
    }
  }
);

export const postMaterial = createAsyncThunk(
  "material/data/post",
  async (data) => {
    try {
      const response = await apipostMaterial(data);
      return response;
    } catch (error) {
      return error?.response || error.toString();
    }
  }
);

export const putMaterial = createAsyncThunk(
  "material/data/put",
  async (data) => {
    try {
      const response = await apiputMaterial(data);
      return response;
    } catch (error) {
      return error?.response || error.toString();
    }
  }
);

export const getFarmerOption = createAsyncThunk(
  "farmer/data/option",
  async (data) => {
    try {
      const response = await apigetFarmerOptions(data);
      return response;
    } catch (error) {
      return error?.response || error.toString();
    }
  }
);

export const initialTableData = {
  total: 0,
  pageIndex: 1,
  pageSize: 10,
  query: "",
  sort: {
    order: "",
    field: "",
  },
};

const dataSlice = createSlice({
  name: "material/data",
  initialState: {
    loading: false,
    materials: [],
    farmers: [],
    tableData: initialTableData,
  },
  reducers: {
    setMaterialList: (state, action) => {
      state.materials = action.payload;
    },
    setTableData: (state, action) => {
      state.tableData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllMaterial.fulfilled, (state, action) => {
        state.materials = action.payload?.data?.data || [];
        state.tableData.total = action.payload?.data?.total || 0;
        state.loading = false;
      })
      .addCase(getAllMaterial.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllMaterial.rejected, (state) => {
        state.loading = false;
        state.materials = [];
        state.tableData.total = 0;
      })
      .addCase(deleteMaterial.fulfilled, (state) => {})
      .addCase(postMaterial.fulfilled, (state) => {})
      .addCase(putMaterial.fulfilled, (state) => {})
      .addCase(getFarmerOption.fulfilled, (state, action) => {
        state.farmers = action.payload?.data?.data || [];
      })
      .addCase(getFarmerOption.pending, (state, action) => {
        state.farmers = [];
      })
      .addCase(getFarmerOption.rejected, (state, action) => {
        state.farmers = [];
      });
  },
});

export const { setMaterialList, setTableData } = dataSlice.actions;

export default dataSlice.reducer;
