import { createSlice } from "@reduxjs/toolkit";

const stateSlice = createSlice({
  name: "sauda/state",
  initialState: {
    deleteConfirmation: false,
    date: "",
  },
  reducers: {
    toggleSetDate: (state, action) => {
      state.date = action.payload;
    },
  },
});

export const { toggleSetDate } = stateSlice.actions;

export default stateSlice.reducer;
